<template>
  <div>
    <v-btn block color="primary" large @click="onAdd">Add pending prompts job</v-btn>
    <v-snackbar
        :color="notify.color"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <!--        <v-icon large class="pr-3">mdi-check-circle</v-icon>-->
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import Http from '@/api/Http';

export default {
  name: 'AdminSystemBull',
  data () {
    return {
      api: Http
          .use('data'),
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      }
    }
  },
  methods: {
    onAdd() {
      console.log('onAdd')
      this.api.post('/admin/system/bull/setup')
          .then(res => {
            console.log(res)
          })
      this.notify = {
        visible: true,
        color: 'success',
        text: 'Pending prompts job added',
        icon: 'mdi-check-circle'
      };
    }
  }
}

</script>