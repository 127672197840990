<template>
  <v-card>
    <v-card-title class="secondary white--text text-h5">
      System Admin
      <v-spacer></v-spacer>
    </v-card-title>
    <v-row
        class="pa-4"
        justify="space-between"
    >
      <v-col cols="12" lg="5" sm="12">

        <v-divider></v-divider>
        <v-list>
          <v-list-item-group
              v-model="sel"
              @change="onGroupClick"
          >
            <template v-for="(item, index) in actions">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title v-text="`${item.name}`">{{index}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="d-flex flex-column">
          <div
              v-if="selected === null"
              class="text-h6 grey--text text--lighten-1 font-weight-light pt-6 mx-auto"
              style="align-self: center;"
          >
            Select an action from the left
          </div>
          <AdminSystemBull v-if="selected === 0" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>


// import AdminUserForm from '@/components/admin/users/AdminUserForm.vue';
import AdminSystemBull from '@/components/admin/system/AdminSystemBull.vue';

export default {
  components: { AdminSystemBull },
  data () {
    return {
      actions: [{ name: 'Bull Setup '}],
      sel: null,
      selected: null
    }
  },
  methods: {
    onGroupClick(evt) {
      console.log(evt)
      this.sel = evt;
      if (evt !== null) {
        this.selected = evt;
      } else {
        this.selected = null;
      }
    },
  }
}
</script>